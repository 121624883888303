


















































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";

@Component({})
export default class AUser extends Vue {

  // 加载状态
  loading = false;
  tableData = []
  status =''
  config = {
    0: '开通',
    1: '关闭'
  }
  configBorker = {
    0: '开启',
    1: '关闭',
  }

  // 开通经纪人申请
  modifye(item: any, row: any){
    api
      .post('J27329', {pmLevel:item.levelCode, brokerApply: row})
      .then(res => {
        this.$message.success('操作成功')
      })
  }

  channelData = []
  getChannel() {
    api
            .post('A23006', {})
            .then(res => {
              this.channelData = res.list
            })
  }

  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    api
      .post("J27142")
      .then((data) => {
        this.tableData = data.list
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 删除
  del(row: any) {
    // this.qingdanTable
    this.qingdanTable.splice(this.qingdanTable.findIndex((item: any) => item.couponNumber == row.couponNumber), 1)
  }

  // 添加
  add(row: any) {
    let a=this.qingdanTable.find((v: any) => v.couponNumber == row.couponNumber)
    if(!a) {
      this.qingdanTable.push(row);
      this.qingdanData.splice(this.qingdanData.findIndex((item: any) => item.couponNumber == row.couponNumber), 1)
    } else {
      this.$message.warning('当前优惠券已添加，不可重复添加')
    }
  }

  // 保存优惠券
  pmcoupon() {
    let list: any = []
    this.qingdanTable.forEach((item: any) => {
      list.push({
        couponNumber: item.couponNumber,
        quantityGifts: item.quantityGifts
      })
    })
    api
      .post('J27326', {pmLevel: this.pmLevel, coupons: list})
      .then(res => {
        this.$message.success('操作成功')
      })
  }

  // 查询优惠券
  search = {}
  qingdanData: any = []
  getzhikecoupon() {
    api
      .post('J27327', this.search)
      .then(res => {
        this.qingdanData = res.list
      })
  }
  // 优惠券清单
  qingdanDialog = false
  qingdanTable: any = []
  keyword = ''
  total = 0
  pmLevel = ''
  toQingDan(row: any) {
    this.pmLevel = row.levelCode
    this.search = {}
    this.qingdanData = []
    api
      .post('J27331', {pmLevel: row.levelCode})
      .then(res => {
        this.qingdanTable = res.coupons
        this.qingdanDialog = true
      })

  }
  changePage(page: number) {
    // this.search.pagination.page = page - 1;
    // (this.$parent as any).searchTable();
  }

  // 保存
  save() {
    this.loading = true;
    api
        .post("J27141", this.tableData)
        .then((data) => {
          this.$message.success('操作成功')
        })
        .finally(() => {
          this.loading = false;
        });
  }


  activated() {
    this.searchTable();
    this.getChannel()
  }
}
